import React from "react"
import Container from "../../components/container"
import CustomHeader from "../../components/customHeader"
import SideBarView from "../../components/SideBarView"
import Seo from "../../components/Seo"
import { Typography, Grid } from "@mui/material"
import { CodeBlockView } from "../../components/HelperComponents"

const programmingRoutineView = (data) => {
  return (
    <div style={{ marginTop: "10px", marginBottom: "15px" }}>
      <Typography variant={`h6`}>{data.title}</Typography>
      <CodeBlockView code={data.code && (data.code).trim()} language={data.language}/>
      {data.description}
    </div>
  )
}


const JavaTimestampConversions = () => {
  return (
    <Container>
      <CustomHeader title={`Java Timestamp Conversions`}/>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={9} xl={9}>
          <Typography variant={`p`}>
            Understanding Timestamps: In Java, a timestamp is a long integer value that represents the number of
            milliseconds since January 1, 1970, 00:00:00 GMT.
            This is known as the Unix Epoch time. It's important to understand this format as it's used in many Java
            applications.
            <br/><br/>
            Converting Timestamps to Dates: To convert a timestamp to a date object in Java, you can use the
            SimpleDateFormat class. This class allows you to specify the format of the date and time values. For
            example, to
            convert a timestamp to a date object in the format "dd/MM/yyyy", you can use the following code:
            {programmingRoutineView({
              language: `java`,
              description: `This will output: "08/03/2021".`,
              code: `long timestamp = 1615225817878L; Date date = new Date(timestamp); 
SimpleDateFormat formatter = new SimpleDateFormat("dd/MM/yyyy"); 
String formattedDate = formatter.format(date); 
System.out.println(formattedDate);`
            })}
            Converting Dates to Timestamps: To convert a date object to a timestamp in Java, you can use the getTime()
            method of the Date class. This method returns the number of milliseconds since January 1, 1970, 00:00:00
            GMT.
            For example
            {programmingRoutineView({
              language: `java`,
              description: `This will output: "1615190400000".`,
              code: `String dateString = "08/03/2021"; 
SimpleDateFormat formatter = new SimpleDateFormat("dd/MM/yyyy"); 
Date date = formatter.parse(dateString); 
long timestamp = date.getTime(); System.out.println(timestamp);`
            })}
            Handling Timezones: When working with timestamps in Java, it's important to consider timezones. By default,
            Java
            uses the system's timezone, but you can specify a specific timezone using the TimeZone class. For example,
            to
            use the timezone "America/Los_Angeles", you can use the following code:
            {programmingRoutineView({
              language: `java`,
              description: `This will output the current timestamp in the "America/Los_Angeles" timezone.`,
              code: `TimeZone timeZone = TimeZone.getTimeZone("America/Los_Angeles"); 
Calendar calendar = Calendar.getInstance(timeZone); 
long timestamp = calendar.getTimeInMillis(); 
System.out.println(timestamp);`
            })}
          </Typography>
          <Typography variant={`h2`}
                      style={{
                        // color: "#4d3267",
                        fontSize: "24px",
                        fontWeight: "bold",
                        fontFamily: "Karla, sans-serif"
                      }}>
            Few more examples of different types of timestamp conversions in Java:
          </Typography>
          {programmingRoutineView({
            title: `Converting Timestamp to Date`,
            language: `java`,
            code: `import java.sql.Timestamp;
import java.util.Date;

public class TimestampToDateExample {
    public static void main(String[] args) {
        Timestamp timestamp = new Timestamp(System.currentTimeMillis());
        Date date = new Date(timestamp.getTime());
        System.out.println("Timestamp: " + timestamp);
        System.out.println("Date: " + date);
    }
}
`
          })}
          {programmingRoutineView({
            title: `Converting Date to Timestamp`,
            language: `java`,
            code: `import java.sql.Timestamp;
import java.util.Date;

public class DateToTimestampExample {
    public static void main(String[] args) {
        Date date = new Date();
        Timestamp timestamp = new Timestamp(date.getTime());
        System.out.println("Date: " + date);
        System.out.println("Timestamp: " + timestamp);
    }
}
`
          })}
          {programmingRoutineView({
            title: `Converting String to Timestamp`,
            language: `java`,
            code: `import java.sql.Timestamp;
import java.text.ParseException;
import java.text.SimpleDateFormat;

public class StringToTimestampExample {
    public static void main(String[] args) throws ParseException {
        String dateString = "2022-03-08 12:00:00";
        SimpleDateFormat dateFormat = new SimpleDateFormat("yyyy-MM-dd hh:mm:ss");
        Date date = dateFormat.parse(dateString);
        Timestamp timestamp = new Timestamp(date.getTime());
        System.out.println("Date String: " + dateString);
        System.out.println("Timestamp: " + timestamp);
    }
}
`
          })}
          {programmingRoutineView({
            title: `Converting Timestamp to String`,
            language: `java`,
            code: `import java.sql.Timestamp;
import java.text.SimpleDateFormat;

public class TimestampToStringExample {
    public static void main(String[] args) {
        Timestamp timestamp = new Timestamp(System.currentTimeMillis());
        SimpleDateFormat dateFormat = new SimpleDateFormat("yyyy-MM-dd hh:mm:ss");
        String dateString = dateFormat.format(timestamp);
        System.out.println("Timestamp: " + timestamp);
        System.out.println("Date String: " + dateString);
    }
}
`
          })}
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3}>
          <SideBarView/>
        </Grid>
      </Grid>
    </Container>
  )
}

export default JavaTimestampConversions

export const Head = () => {
  return (
    <Seo
      title="Effortlessly Convert Timestamps in Java: A Comprehensive Guide"
      canonicalUrl={"https://www.epochconverter.io/blogs/java-timestamp-conversions"}
    />
  )
}
